import React from 'react'
import Layout from '../templates/Main'
import Heading from '../modules/Heading'
import { Box, Flex, Text, Image } from 'rebass/styled-components'

const Terms = ({ params }) => {
    return (
        <Layout id={params.id}>
            <Heading size='h1' text={'Terms & Conditions'} />
            <Flex variant='container'>
                <Box width='30%'>
                    <Text as='h2' variant='h2'mx='sm' >
                        INTELLECTUAL PROPERTY RIGHTS
                    </Text>
                    <Text as='p' variant='copy' color='grey' mx='sm' my='sm'>
                        We are the owner or the licensee of all intellectual property rights in our site, and in the material published on it. Those works are protected by copyright laws and treaties around the world. All such rights are reserved.
                    </Text>

                    <Text as='p' variant='copy' color='grey' mx='sm' my='sm'>

                        Much of the content found on this site has been submitted by third party information providers and partners. Any copying, redistribution, publication or retransmission of any portion of website material, is strictly prohibited without the express written permission of Kidston SA.
                    </Text>

                    <Text as='p' variant='copy' color='grey' mx='sm' my='sm'>

                        Any third party manufacturer logos, symbols, images, badges or trademarks appearing on the site are at all times the property of the third party manufacturer.
                    </Text>

                    <Text as='p' variant='copy' color='grey' mx='sm' my='sm'>

                        You may not copy, or download extracts, of any page(s) from our site for your personal reference. You may not draw the attention of others within your organisation to material posted on our site.
                    </Text>

                    <Text as='p' variant='copy' color='grey' mx='sm' my='sm'>

                        Our status (and that of any identified contributors) as the authors of material on our site must always be acknowledged.
                    </Text>

                    <Text as='p' variant='copy' color='grey' mx='sm' my='sm'>

                        You must not use any part of the materials on our site for commercial purposes without obtaining a licence to do so from us or our licensors.
                    </Text>

                    <Text as='p' variant='copy' color='grey' mx='sm' my='sm'>

                        If you print off, copy or download any part of our site in breach of these terms of use, your right to use our site will cease immediately and you must, at our option, return or destroy any copies of the materials you have made.
                    </Text>

                    <Text as='p' variant='copy' color='grey' mx='sm' mt='sm' mb='xl'>

                        Although every effort is made to ensure the accuracy of the information on this website, Kidston SA cannot guarantee it.
                    </Text>
                </Box>
                <Box width='70%'>
                    <Image width='100%' src='https://res.cloudinary.com/kidston/image/upload/v1633334091/restorations/miura.png' />
                </Box>
            </Flex>
        </Layout>
    )
}

export default Terms
